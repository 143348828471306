import { IDfaOwnerBalance } from '@dltru/dfa-models'
import {
    CaseBadge,
    ColumnsType,
    DetailsAmountCell,
    DetailsAmountHeader,
    LinkCell,
} from '@dltru/dfa-ui'

import './style.less'

const CaseCell = (value: number) => <CaseBadge count={value.toLocaleString()} />

export const balancesGridConfig: ColumnsType<IDfaOwnerBalance> = [
    {
        title: 'Тикер',
        dataIndex: 'asset_ticker_symbol',
        key: 'asset_ticker_symbol',
        sorter: true,
        ellipsis: true,
        render: (value, record) => LinkCell(value, `/offers/${record.asset_id}`),
    },
    {
        title: 'Наименование ЦФА',
        dataIndex: 'asset_title',
        key: 'asset_title',
        sorter: true,
        ellipsis: true,
        render: (value, record) => LinkCell(value, `/offers/${record.asset_id}`),
    },
    {
        title: 'Эмитент',
        dataIndex: 'emitter_full_name',
        key: 'emitter_full_name',
        sorter: true,
        ellipsis: true,
        render: (value, record) => LinkCell(value, `/clients/${record.emitter_id}`),
    },
    {
        title: 'Всего ЦФА, ед.',
        dataIndex: 'total',
        key: 'total',
        align: 'right',
        sorter: true,
        render: (_, record) =>
            CaseCell(
                record.active_amount_dfa +
                    record.blocked_amount_dfa +
                    record.encumbrance_amount_dfa +
                    record.injunction_amount_dfa +
                    (record.early_repayment_blocked_amount_dfa ?? 0),
                // (record.early_repayment_amount_dfa ?? 0),
            ),
    },
    {
        title: DetailsAmountHeader,
        dataIndex: 'details_amount_dfa',
        key: 'details_amount_dfa',
        render: DetailsAmountCell,
    },
    /*    // TODO: вернуть позже
   {
       title: 'Средняя цена покупки, ₽',
       dataIndex: 'price_per_dfa',
       key: 'price_per_dfa',
       align: 'right',
       sorter: true,
       render: CurrencyCell,
   },
    {
        title: 'Стоимость, ₽',
        dataIndex: 'total_price',
        key: 'total_price',
        align: 'right',
        sorter: true,
        render: CurrencyCell,
    },
    {
        title: 'Статус',
        dataIndex: 'status',
        key: 'status',
        sorter: true,
        fixed: 'right',
        render: TagCell,
    },*/
]
