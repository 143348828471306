import { IBusinessmanProfilePublic } from '@dltru/dfa-models'
import { Card, CardContent, CardField, CardHeader, EMPTY_FIELD_VALUE, Space } from '@dltru/dfa-ui'
import React, { FC } from 'react'

import { LicenseInfoCard } from './LicenseInfoCard'

interface IComponentProps {
    profile: IBusinessmanProfilePublic
    userFullName: string
}

export const OtherBusinessman: FC<IComponentProps> = ({ profile, userFullName }) => {
    return (
        <Card>
            <CardHeader title="Общая информация" />
            <CardContent>
                <CardField
                    title="ФИО"
                    value={`${profile.last_name} ${profile.first_name} ${profile.second_name}`}
                />
                <CardField title="ОГРНИП" value={profile.ogrn} />
                <CardField
                    title="Сайт"
                    value={
                        <Space direction="vertical">
                            {profile.websites?.map((url) => (
                                <a href={url} target="_blank" rel="noreferrer">
                                    {url}
                                </a>
                            )) ?? EMPTY_FIELD_VALUE}
                        </Space>
                    }
                />
                {profile?.lic_activities && (
                    <>
                        {profile.lic_activities.map((data) => (
                            <LicenseInfoCard
                                key={data.lic_number}
                                data={data}
                                userFullName={userFullName}
                            />
                        ))}
                    </>
                )}
            </CardContent>
        </Card>
    )
}
