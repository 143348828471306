import { IIndividualProfilePublic } from '@dltru/dfa-models'
import { Card, CardContent, CardField, CardHeader } from '@dltru/dfa-ui'
import React, { FC } from 'react'

interface IComponentProps {
    profile: IIndividualProfilePublic
}

export const OtherIndividual: FC<IComponentProps> = ({ profile }) => {
    return (
        <Card>
            <CardHeader title="Общая информация" />
            <CardContent>
                <CardField
                    title="ФИО"
                    value={`${profile.last_name} ${profile.first_name} ${profile.second_name}`}
                />
                <CardField title="ИНН" value={profile.inn} />
            </CardContent>
        </Card>
    )
}
