import { LicActivityModel } from '@dltru/dfa-models'
import {
    ArrowDownSmall,
    ArrowUpSmall,
    Box,
    Button,
    CardField,
    EMPTY_FIELD_VALUE,
    Space,
    VIEW_DATE_FORMAT,
} from '@dltru/dfa-ui'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { FC, useState } from 'react'

import { UneditableUploadedFiles } from '@components/Form'

import style from './style.m.less'

interface IComponentProps {
    data: LicActivityModel
    userFullName: string
}
export const LicenseInfoCard: FC<IComponentProps> = ({ data, userFullName }) => {
    const [isOpen, setIsOpen] = useState(false)

    const openToggle = (e: React.SyntheticEvent): void => {
        e.preventDefault()
        setIsOpen(!isOpen)
    }

    return (
        <Box className={clsx(style.infoCard, { [style.open]: isOpen })} padding={[16, 16, 16, 24]}>
            <span className={style.grayTitle}>Лицензия</span>
            <span className={style.blackTitle}>{isOpen ? userFullName : data.type_activity}</span>

            {isOpen && (
                <div>
                    <CardField title="Вид деятельности" value={data.type_activity} />
                    <CardField title="Номер" value={data.lic_number} />
                    <CardField
                        title="Дата выдачи"
                        value={dayjs.unix(data.date_of_issue).format(VIEW_DATE_FORMAT)}
                    />
                    <CardField title="Кем выдана" value={data.issue_by} />
                    <CardField
                        title="Срок действия"
                        value={
                            data.validity
                                ? dayjs.unix(data.validity).format(VIEW_DATE_FORMAT)
                                : EMPTY_FIELD_VALUE
                        }
                    />
                    {data.file_uuid && (
                        <UneditableUploadedFiles files={[{ uid: data.file_uuid }]} />
                    )}
                </div>
            )}
            <Button type="link" onClick={openToggle} size="small" className={style.customButton}>
                {isOpen ? (
                    <Space>
                        Свернуть информацию <ArrowUpSmall />
                    </Space>
                ) : (
                    <Space>
                        Подробнее <ArrowDownSmall />
                    </Space>
                )}
            </Button>
        </Box>
    )
}
