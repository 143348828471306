import {
    IBusinessmanProfilePublic,
    IIndividualProfilePublic,
    ILegalEntityProfilePublic,
    ProfileType,
} from '@dltru/dfa-models'
import { Box, CopyButton, PageHeader, Space, UserOutlined } from '@dltru/dfa-ui'
import { getUserFullNameByType } from '@utils/profile'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { profileDetailsSlice } from '@store/profiles/details'
import { profileSelector } from '@store/profiles/details/selectors'

import { OtherBusinessman } from './components/OtherBusinessman'
import { OtherIndividual } from './components/OtherIndividual'
import { OtherLegalEntity } from './components/OtherLegalEntity'

interface IComponentProps {
    uuid: string
}
export const OtherClient: FC<IComponentProps> = ({ uuid }) => {
    const reduxDispatch = useDispatch()

    const profile = useSelector(profileSelector.selectOtherClient)
    const profileType = useSelector(profileSelector.selectOtherClientProfileType)

    useEffect(() => {
        if (uuid) {
            reduxDispatch(profileDetailsSlice.actions.getOtherClient(uuid))
        }
        return () => {
            reduxDispatch(profileDetailsSlice.actions.clearOtherClient())
        }
    }, [uuid])

    if (!profile) {
        return null
    }

    const userFullName = getUserFullNameByType(profileType, profile)
    return (
        <>
            <Box padding={16}>
                <PageHeader
                    title={
                        <Space>
                            <UserOutlined />
                            <span>{userFullName}</span>
                        </Space>
                    }
                    subTitle={
                        <Box margin={[0, 0, 0, 28]} direction="row">
                            ID {uuid} {uuid && <CopyButton text={uuid} />}
                        </Box>
                    }
                />
            </Box>
            {profileType === ProfileType.LEGL && (
                <OtherLegalEntity
                    profile={profile as ILegalEntityProfilePublic}
                    userFullName={userFullName as string}
                />
            )}
            {profileType === ProfileType.PRSN && (
                <OtherIndividual profile={profile as IIndividualProfilePublic} />
            )}
            {profileType === ProfileType.BUSN && (
                <OtherBusinessman
                    profile={profile as IBusinessmanProfilePublic}
                    userFullName={userFullName as string}
                />
            )}
        </>
    )
}
