import { EncumbranceShortType } from '@dltru/dfa-models'
import { DEFAULT_PER_PAGE, Table } from '@dltru/dfa-ui'
import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { walletEncumbranceSlice } from '@store/wallet/encumbrance'
import { encumbranceSelectors } from '@store/wallet/encumbrance/selectors'

import { encumbranceGridConfig } from './encumbranceGridConfig'

export const EncumbranceTable: FC = () => {
    const reduxDispatch = useDispatch()
    const navigate = useNavigate()
    const items = useSelector(encumbranceSelectors.selectItems)
    const paging = useSelector(encumbranceSelectors.selectPaging)
    const isLoading = useSelector(encumbranceSelectors.selectIsLoading)

    const onPerPageChange = (limit: number) => {
        reduxDispatch(walletEncumbranceSlice.actions.getItems({ limit }))
    }

    const onNext = () => {
        reduxDispatch(walletEncumbranceSlice.actions.getItems({ cursor: paging?.cursors?.next }))
    }

    const onPrev = () => {
        reduxDispatch(walletEncumbranceSlice.actions.getItems({ cursor: paging?.cursors?.prev }))
    }

    useEffect(() => {
        reduxDispatch(
            walletEncumbranceSlice.actions.getItems({
                limit: DEFAULT_PER_PAGE,
            }),
        )
    }, [])

    const onRow = (record: EncumbranceShortType) => ({
        onClick: () => {
            navigate(`/encumbrance/${record.uuid}`)
        },
    })

    return (
        <Table
            columns={encumbranceGridConfig}
            isLoading={isLoading}
            dataSource={items}
            cursors={paging?.cursors}
            onPerPage={onPerPageChange}
            onNext={onNext}
            onPrev={onPrev}
            onRow={onRow}
            clickableRow
            scroll={{ y: undefined }}
        />
    )
}
