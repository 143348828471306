// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".infoCard__6OP3 {\n  margin-top: 24px;\n  background: #f8f8fa;\n  border: 1px solid #ebecf0;\n  border-radius: 16px;\n  width: 520px;\n  max-width: 100%;\n}\n.infoCard__6OP3 .ant-btn-link {\n  margin-top: 8px;\n  font-weight: 400;\n  padding: 0;\n  font-size: 12px;\n}\n.infoCard__6OP3 .ant-btn-link.ant-btn-sm {\n  height: 20px;\n}\n.infoCard__6OP3 .ant-btn-link .anticon {\n  vertical-align: -4px;\n}\n.grayTitle_B60gG {\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 20px;\n  color: #868991;\n}\n.blackTitle_XFxLo {\n  margin-top: 8px;\n  font-weight: 600;\n  font-size: 14px;\n  line-height: 22px;\n  color: #303440;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/ClientCard/OtherClient/components/LicenseInfoCard/style.m.less"],"names":[],"mappings":"AAEA;EACI,gBAAA;EACA,mBAAA;EACA,yBAAA;EACA,mBAAA;EACA,YAAA;EACA,eAAA;AADJ;AALA;EASY,eAAA;EACA,gBAAA;EACA,UAAA;EACA,eAAA;AADZ;AAEY;EACI,YAAA;AAAhB;AAdA;EAiBgB,oBAAA;AAAhB;AAMA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAJJ;AAMA;EACI,eAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAJJ","sourcesContent":["@import 'style/palette';\n\n.infoCard {\n    margin-top: 24px;\n    background: @gray-2;\n    border: 1px solid @gray-4;\n    border-radius: 16px;\n    width: 520px;\n    max-width: 100%;\n    :global {\n        .ant-btn-link {\n            margin-top: 8px;\n            font-weight: 400;\n            padding: 0;\n            font-size: 12px;\n            &.ant-btn-sm {\n                height: 20px;\n            }\n            .anticon {\n                vertical-align: -4px;\n            }\n        }\n    }\n}\n\n.grayTitle {\n    font-weight: 400;\n    font-size: 12px;\n    line-height: 20px;\n    color: @gray-7;\n}\n.blackTitle {\n    margin-top: 8px;\n    font-weight: 600;\n    font-size: 14px;\n    line-height: 22px;\n    color: @gray-10;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoCard": "infoCard__6OP3",
	"grayTitle": "grayTitle_B60gG",
	"blackTitle": "blackTitle_XFxLo"
};
export default ___CSS_LOADER_EXPORT___;
