import { UnionDealFront, getDealTypeTranslateClient, isTransferDeal } from '@dltru/dfa-models'
import {
    ColoredTag,
    ColumnsType,
    DateTimeCell,
    EMPTY_FIELD_VALUE,
    LinkCell,
    getRublesFromPennies,
} from '@dltru/dfa-ui'

const AssetLink = (value: string, record: UnionDealFront) => {
    const asset_id =
        record?.asset_id ||
        record?.buy_order?.asset_id ||
        record?.sale_order?.asset_id ||
        record?.order?.asset_id
    return asset_id ? LinkCell(value, `/offers/${asset_id}`) : EMPTY_FIELD_VALUE
}
const AmountCell = (value: number, record: UnionDealFront) => {
    if (!value) {
        return EMPTY_FIELD_VALUE
    }

    return (
        <ColoredTag type={record.type === 'repayment' ? 'gray' : 'blue'}>
            {value.toLocaleString()}
        </ColoredTag>
    )
}
const TotalPriceCell = (_: number, record: UnionDealFront) => {
    if (record.type === 'repayment') {
        return record.total_price ? getRublesFromPennies(record.total_price) : 0
    }
    if (record.type === 'transferV2') {
        return EMPTY_FIELD_VALUE
    }
    if (isTransferDeal(record)) {
        return record.total_price ? getRublesFromPennies(record.total_price) : EMPTY_FIELD_VALUE
    }
    return getRublesFromPennies(record.price_per_dfa * record.amount_dfa)
}

export const gridConfig: ColumnsType<UnionDealFront> = [
    {
        title: 'Дата и время',
        dataIndex: 'executed_at',
        key: 'executed_at',
        render: DateTimeCell,
    },
    {
        title: 'Тикер',
        dataIndex: 'asset_ticker_symbol',
        key: 'asset_ticker_symbol',
        render: AssetLink,
    },
    {
        title: 'Эмитент',
        dataIndex: 'emitter_id',
        key: 'emitter_id',
        render: (value, record) => LinkCell(record.emitter_full_name || value, `/clients/${value}`),
    },
    {
        title: 'Тип операции',
        dataIndex: 'type',
        key: 'type',
        render: getDealTypeTranslateClient,
    },
    {
        title: 'Количество',
        dataIndex: 'amount_dfa',
        key: 'amount_dfa',
        render: (value, record) => (value ? AmountCell(value, record) : '---'),
    },
    {
        title: 'Цена, ₽',
        dataIndex: 'price_per_dfa',
        key: 'price_per_dfa',
        render: (value, record) => {
            if (record.type === 'repayment') {
                return value ? getRublesFromPennies(value) : 0
            }
            return value ? getRublesFromPennies(value) : EMPTY_FIELD_VALUE
        },
    },
    {
        title: 'Сумма сделки, ₽',
        dataIndex: 'total_price',
        key: 'total_price',
        render: TotalPriceCell,
    },
]
