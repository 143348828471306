import { DocumentsEnum, IDfaOwnerBalance } from '@dltru/dfa-models'
import { Table } from '@dltru/dfa-ui'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { authSelector } from '@store/auth/selectors'
import { getTableData } from '@store/wallet/assets/selectors'

import { DownloadBtn } from '@components/DownloadBtn/DownloadBtn'

import { balancesGridConfig } from './balancesGridConfig'

export const BalanceTable: FC = () => {
    const userId = useSelector(authSelector.selectUserUid)
    const walletData = useSelector(getTableData)
    const navigate = useNavigate()

    const onRow = (record: IDfaOwnerBalance) => ({
        onClick: () => {
            navigate(`/offers/${record.asset_id}`)
        },
    })

    return (
        <Table
            columns={balancesGridConfig}
            dataSource={walletData.Rows}
            isLoading={walletData.loading}
            onRow={onRow}
            clickableRow
            scroll={{ y: undefined }}
            rowKey="id"
            footerLeft={
                <DownloadBtn
                    documentType={DocumentsEnum.user_dfa_wallet}
                    params={{ user_id: userId }}
                    title="Отчёт о ЦФА"
                />
            }
        />
    )
}
