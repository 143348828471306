import { ILegalEntityProfilePublic } from '@dltru/dfa-models'
import {
    Card,
    CardContent,
    CardField,
    CardHeader,
    Divider,
    EMPTY_FIELD_VALUE,
    Space,
} from '@dltru/dfa-ui'
import React, { FC } from 'react'

import { LicenseInfoCard } from './LicenseInfoCard'

interface IComponentProps {
    profile: ILegalEntityProfilePublic
    userFullName: string
}

export const OtherLegalEntity: FC<IComponentProps> = ({ profile, userFullName }) => {
    return (
        <Card>
            <CardHeader title="Общая информация" />
            <CardContent>
                <CardField title="Наименование" value={profile.title} />
                <CardField title="Организационно-правовая форма" value={profile.legal_form} />
                <Divider />
                <CardField title="ИНН" value={profile.inn} />
                <CardField title="ОКПО" value={EMPTY_FIELD_VALUE} />
                <CardField title="ОГРН" value={profile.ogrn} />
                <CardField title="БИК" value={profile.bik} />
                <Divider />
                <CardField title="Юридический адрес" value={profile.legal_address} />
                <CardField
                    title="Сайт"
                    value={
                        <Space direction="vertical">
                            {profile.websites?.map((url) => (
                                <a href={url} target="_blank" rel="noreferrer">
                                    {url}
                                </a>
                            )) ?? EMPTY_FIELD_VALUE}
                        </Space>
                    }
                />
                {profile.lic_activities && (
                    <>
                        {profile.lic_activities.map((data) => (
                            <LicenseInfoCard
                                key={data.lic_number}
                                data={data}
                                userFullName={userFullName}
                            />
                        ))}
                    </>
                )}
            </CardContent>
        </Card>
    )
}
