import { Box, Card, CardContent, Col, Divider, Row, Space, TabButton } from '@dltru/dfa-ui'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { authSelector } from '@store/auth/selectors'
import { walletAssetsSlice } from '@store/wallet/assets'
import { walletHistorySlice } from '@store/wallet/history'

import { BalanceTable } from './BalanceTable'
import { DealsTable } from './DealsTable'
import { EncumbranceTable } from './EncumbranceTable'
import { InjunctionsTable } from './InjunctionsTable'

enum Tab {
    ASSETS,
    OTHER,
    DEALS,
    INJUNCTIONS,
}

export const Wallet: React.FC = () => {
    const userId = useSelector(authSelector.selectUserUid)

    const dispatch = useDispatch()

    const [innerTab, setInnerTab] = useState<Tab>(Tab.ASSETS)

    useEffect(() => {
        if (userId) {
            dispatch(walletAssetsSlice.actions.loadWalletAssets(userId))
            dispatch(walletHistorySlice.actions.loadWalletHistory(userId))
        }
    }, [userId])

    return (
        <Card>
            <CardContent>
                <Row>
                    <Col span={12}>
                        <Box padding={[16, 0, 0, 0]}>
                            <Space>
                                <TabButton
                                    isActiveTab={innerTab === Tab.ASSETS}
                                    onClick={() => setInnerTab(Tab.ASSETS)}
                                >
                                    Мои активы
                                </TabButton>
                                <TabButton
                                    isActiveTab={innerTab === Tab.OTHER}
                                    onClick={() => setInnerTab(Tab.OTHER)}
                                >
                                    Прочие активы
                                </TabButton>
                                <TabButton
                                    isActiveTab={innerTab === Tab.DEALS}
                                    onClick={() => setInnerTab(Tab.DEALS)}
                                >
                                    История операций с ЦФА
                                </TabButton>
                                <TabButton
                                    isActiveTab={innerTab === Tab.INJUNCTIONS}
                                    onClick={() => setInnerTab(Tab.INJUNCTIONS)}
                                >
                                    Блокирования
                                </TabButton>
                            </Space>
                        </Box>
                    </Col>
                    {/*
                    <Col span=InjustionsTable{4}>
                        <CardVerticalField title="ID Кошелька" value="2c3bb8443a1af" />
                    </Col>
                    <Col span={4}>
                        <CardVerticalField title="Создан" value="28.01.2022" />
                    </Col>
                    */}
                </Row>
                <Divider />
                {innerTab === Tab.ASSETS && <BalanceTable />}
                {innerTab === Tab.OTHER && <EncumbranceTable />}
                {innerTab === Tab.DEALS && <DealsTable />}
                {innerTab === Tab.INJUNCTIONS && <InjunctionsTable />}
            </CardContent>
        </Card>
    )
}
