import {
    DocumentsEnum,
    UnionDealFront,
    isEmissionDeal,
    isRepaymentDeal,
    isSecondaryDeal,
    isTransferDeal,
} from '@dltru/dfa-models'
import { Table } from '@dltru/dfa-ui'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import { authSelector } from '@store/auth/selectors'
import { getTableData } from '@store/wallet/history/selectors'

import { DownloadBtn } from '@components/DownloadBtn/DownloadBtn'
import { emissionModal } from '@components/Modals/Deals/View/Issue'
import { repaymentModal } from '@components/Modals/Deals/View/Repayment'
import { secondaryModal } from '@components/Modals/Deals/View/Secondary'
import { transferModal } from '@components/Modals/Deals/View/Transfer'

import { transferV2Modal } from './TransferV2Modal'
import { gridConfig } from './historyGridConfig'

const showDealViewModal = async (deal: UnionDealFront, user_uuid?: string) => {
    const type = deal.type
    if (isEmissionDeal(deal)) {
        return emissionModal({ deal })
    }
    if (isSecondaryDeal(deal)) {
        return secondaryModal({ deal, type })
    }
    if (isRepaymentDeal(deal)) {
        return repaymentModal({ deal })
    }

    if (isTransferDeal(deal)) {
        return transferModal({ deal, type })
    }

    if (deal.type === 'transferV2' && user_uuid) {
        transferV2Modal({ uuid: deal.uuid, user_uuid })
    }
}

export const DealsTable: FC = () => {
    const historyData = useSelector(getTableData)
    const currentUserId = useSelector(authSelector.selectUserUid)
    const user_uuid = useSelector(authSelector.selectUserUid)

    const onRow = (record: UnionDealFront) => ({
        onClick: () => {
            showDealViewModal(record, user_uuid)
        },
    })

    return (
        <Table
            columns={gridConfig}
            dataSource={historyData.Rows}
            isLoading={historyData.loading}
            onRow={onRow}
            clickableRow
            scroll={{ y: undefined }}
            rowKey={(record: UnionDealFront) => `${record.id}${record.type}`}
            footerLeft={
                <DownloadBtn
                    documentType={DocumentsEnum.user_dfa_operations_history}
                    params={{ user_id: currentUserId }}
                    title="Скачать историю операций"
                />
            }
        />
    )
}
