import { FC } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { authSelector } from '@store/auth/selectors'

import { MyProfile } from './MyProfile'
import { OtherClient } from './OtherClient'

const ClientCard: FC = () => {
    const params = useParams()
    const urlUuid = params.id
    const userUID = useSelector(authSelector.selectUserUid)

    if (!urlUuid) {
        return null
    }

    return <>{userUID === urlUuid ? <MyProfile /> : <OtherClient uuid={urlUuid} />}</>
}

export default ClientCard
